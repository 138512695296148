.app {
  width: 100%;
  height: 100%;
  background: var(--color-black);
  color: var(--color-white);
  transition: 0.5s;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
}

.app--active {
  background: var(--color-white);
  color: var(--color-off-black);

  .function {
    border-bottom: var(--color-white);
  }

  .header {
    background: var(--color-white);;
  }

  .header__nav-item {
    color: var(--color-off-black)
  }

  .hero__title__about,
  .hero__title__portfolio,
  .hero__title__contact {
    color: var(--color-off-black);
  }
}