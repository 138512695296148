p,
pre,
blockquote,
a,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
fieldset {
  margin: 0;
}

button {
  background: none;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  &:focus {
    outline: none !important;
  }
}

iframe {
  border: 0;
  max-width: 100%;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

button,
a {
  cursor: pointer;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}