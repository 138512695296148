h2 {
  color: var(--color-green);
  font-size: 1.5rem;
  line-height: 2.4rem;
  padding-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 1.8rem;
    line-height: 3rem;
    padding-bottom: 15px;
  }

  @media (min-width: 1024px) {
    font-size: 2.1rem;
    line-height: 3.2rem;
  }

  @media (min-width: 1440px) {
    padding-bottom: 20px;
  }
}

p, a {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
    line-height: 2.1rem;
    margin-bottom: 10px;
  }

  @media (min-width: 1440px) {
    font-size: 1.5rem;
    line-height: 2.4rem;
    margin-bottom: 12px;
  }
}