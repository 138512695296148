.main {
  background-image: url(../images/background.png);
  height: 100%;
  width: 100%;
  
  .opacity {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .7);
  }

  span {
    color: var(--color-pink);
  }

  &__container {
    background-color: rgba(0, 0, 0, .9);
    margin: 10px 0;
    padding: 10px;
    width: 95%;

    @media (min-width: 768px) {
      padding: 20px;
    }

    &__subtitle {
      font-weight: bold;
      font-style: italic;
    }

    &-project {
      border-bottom: 1px solid var(--color-pink);
      padding: 10px 0 20px;
      margin-bottom: 20px;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
      }

      @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
      }

      &__image {
        margin-bottom: 15px;

        @media (min-width: 1024px) {
          margin-right: 20px;
          width: calc(50%);
        }
      }

      &__subtitle {
        @include hoverLineGrow(--color-black, --color-purple);
        color: var(--color-pink);
        font-weight: bold;
        font-style: italic;
        text-decoration: none;

        &:hover {
          color: var(--color-purple);
        }
      }
    }

    &__text {
      &-extra {
        padding-bottom: 25px;

        @media (min-width: 768px) {
          padding-bottom: 30px;
        }

        @media (min-width: 1024px) {
          padding-bottom: 35px;
        } 
      }

      &-link {
        @include hoverLineGrow(--color-black, --color-purple);
        color: var(--color-pink);
        text-decoration: none;

        &:hover {
          color: var(--color-purple);
        }
      }
    }

    &-item {
      display: flex;
      text-decoration: none;

      &:hover {
        .main__container-item__text {
          color: var(--color-purple);
        }
      }

      &__text {
        color: var(--color-white);
      }

      &__icon {
        align-self: center;
        margin: 0 10px 8px 0;
        width: 20px;

        @media (min-width: 768px) {
          margin: 0 15px 12px 0;
        }

        @media (min-width: 1024px) {
          margin: 0 15px 15px 0;
          width: 25px;
        }

        @media (min-width: 1440px) {
          margin: 0 20px 18px 0;
          width: 30px;
        }
      }
    }
  }
}