.header {
  align-items: center;
  background: var(--color-black);
  border-bottom: 1px solid var(--color-pink);
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 0 15px 0;
  position: fixed;
  transition: 0.5s;
  width: 100%;
  z-index: 5;

  @media (min-width: 768px) {
    padding: 0 30px 0;
  }

  @media (min-width: 1024px) {
    padding: 0 45px 0;
  }

  @media (min-width: 1440px) {
    padding: 0 60px 0;
  }

  &__logo {
    width: 35px;
  }

  &__nav {
    &-item {
      @include hoverLineGrow(--color-black, --color-purple);
      margin-left: 10px;
      color: var(--color-green);
      text-decoration: none;
      font-size: .9rem;
      line-height: 1.8rem;
    
      @media (min-width: 768px) {
        font-size: 1rem;
        line-height: 2rem;
      }

      @media (min-width: 1024px) {
        font-size: 1.2rem;
        line-height: 2.1rem;
      }

      &:hover {
        color: var(--color-purple);
      }
    }
  }
}