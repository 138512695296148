.footer {
  align-items: center;
  background: var(--color-black);
  display: flex;
  justify-content: center;
  height: 60px;

  &__link {
    margin: 5px;
    text-decoration: none;

    &-icon {
      width: 20px;

      @media (min-width: 1024px) {
        width: 25px;
      }

      @media (min-width: 1440px) {
        width: 30px;
      }
    }
  }
}