:root {
  --mobile: 320px;
  --mobile-landscape: 480px;
  --tablet-portrait: 768px;
  --tablet-landscape: 1024px;
  --desktop: 1440px;
  --wide-desktop: 1680px;
}

@custom-media --from-mobile-landscape (min-width: 480px);
@custom-media --from-tablet-portrait (min-width: 768px);
@custom-media --from-tablet-landscape (min-width: 1024px);
@custom-media --from-desktop (min-width: 1440px);
@custom-media --from-wide-desktop (min-width: 1680px);