.three {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  
  &__cube {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__scroll span {
    bottom: 120px;
    position: absolute;
    width: 20px;
    height: 20px;
    display: block;
    border-right: 3px solid var(--color-pink);
    border-bottom: 3px solid var(--color-pink);
    transform: rotate(45deg);
    animation: animate 2s infinite;
  }

  &__scroll span:nth-child(2) {
    animation-delay: -0.2s;
  }
  
  &__scroll span:nth-child(3) {
    animation-delay: -0.4s;
  }
  
  @keyframes animate {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: rotate(45deg) translate(20px, 20px);
    }
    100% {
      opacity: 0;
    }
  }
}